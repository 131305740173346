import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
import { ColorSwatch, ColorSwatchGrid } from '../../../components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`The visualization color palette spans 20 hues. There is a subtle pattern to the saturation level of each hue band – hue 1 bright, hue 2 muted, hue 3 bright, hue 4 muted, etc… That pattern helps create distinction between hues.`}</p>
    <h2>{`Color Algorithm`}</h2>
    <p>{`[Information about algorithm used in charting]`}{`.`}</p>
    <p>{`Level 50 colors are only used as hover highlights or background colors, and are not used in visualizations.`}</p>
    <h2>{`Full Palette`}</h2>
    <h3>{`Pear`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-pear-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-pear-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-pear-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-pear-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-pear-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-pear-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-pear-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-pear-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-pear-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-pear-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-pear-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Sage`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-sage-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-sage-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-sage-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-sage-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-sage-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-sage-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-sage-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-sage-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-sage-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-sage-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-sage-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Jade`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-jade-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-jade-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-jade-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-jade-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-jade-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-jade-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-jade-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-jade-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-jade-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-jade-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-jade-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Teal`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-teal-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-teal-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-teal-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-teal-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-teal-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-teal-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-teal-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-teal-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-teal-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-teal-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-teal-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Turquoise`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-turquoise-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-turquoise-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-turquoise-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-turquoise-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-turquoise-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-turquoise-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-turquoise-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-turquoise-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-turquoise-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-turquoise-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-turquoise-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Cerulean`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-cerulean-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-cerulean-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-cerulean-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-cerulean-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-cerulean-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-cerulean-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-cerulean-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-cerulean-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-cerulean-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-cerulean-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-cerulean-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`OpenGov`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-opengov-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-opengov-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-opengov-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-opengov-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-opengov-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-opengov-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-opengov-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-opengov-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-opengov-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-opengov-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-opengov-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Periwinkle`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-periwinkle-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-periwinkle-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-periwinkle-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-periwinkle-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-periwinkle-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-periwinkle-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-periwinkle-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-periwinkle-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-periwinkle-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-periwinkle-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-periwinkle-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Royal`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-royal-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-royal-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-royal-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-royal-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-royal-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-royal-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-royal-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-royal-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-royal-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-royal-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-royal-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Indigo`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-indigo-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-indigo-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-indigo-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-indigo-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-indigo-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-indigo-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-indigo-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-indigo-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-indigo-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-indigo-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-indigo-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Violet`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-violet-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-violet-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-violet-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-violet-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-violet-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-violet-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-violet-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-violet-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-violet-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-violet-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-violet-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Purple`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-purple-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-purple-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-purple-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-purple-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-purple-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-purple-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-purple-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-purple-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-purple-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-purple-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-purple-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Magenta`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-magenta-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-magenta-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-magenta-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-magenta-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-magenta-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-magenta-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-magenta-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-magenta-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-magenta-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-magenta-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-magenta-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Rose`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-rose-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-rose-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-rose-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-rose-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-rose-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-rose-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-rose-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-rose-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-rose-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-rose-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-rose-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Ruby`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-ruby-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-ruby-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-ruby-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-ruby-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-ruby-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-ruby-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-ruby-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-ruby-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-ruby-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-ruby-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-ruby-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Port`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-port-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-port-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-port-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-port-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-port-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-port-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-port-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-port-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-port-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-port-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-port-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Red`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-red-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-red-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-red-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-red-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-red-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-red-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-red-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-red-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-red-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-red-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-red-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Terracotta`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-terracotta-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-terracotta-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-terracotta-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-terracotta-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-terracotta-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-terracotta-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-terracotta-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-terracotta-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-terracotta-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-terracotta-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-terracotta-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Orange`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-orange-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-orange-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-orange-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-orange-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-orange-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-orange-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-orange-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-orange-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-orange-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-orange-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-orange-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>
    <h3>{`Yellow`}</h3>
    <ColorSwatchGrid mdxType="ColorSwatchGrid">
      <ColorSwatch size="small" name="50" value="viz-yellow-50" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="100" value="viz-yellow-100" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="200" value="viz-yellow-200" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="300" value="viz-yellow-300" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="400" value="viz-yellow-400" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="500" value="viz-yellow-500" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="600" value="viz-yellow-600" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="700" value="viz-yellow-700" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="800" value="viz-yellow-800" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="900" value="viz-yellow-900" mdxType="ColorSwatch" />
      <ColorSwatch size="small" name="1000" value="viz-yellow-1000" mdxType="ColorSwatch" />
    </ColorSwatchGrid>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      